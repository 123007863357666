var main = {

	initialize: function() {
		$(".image-picker").imagepicker();
		$('.wysi').wysihtml5({
			"font-styles": false,
			"stylesheets": false,
			"link": true, 
			"image": true
		});

		main.bindEvents();
	},

	bindEvents: function() {
		//order functions
		$(".up,.down").click(orderer.setUpDown);
		$('.btn-order').click(orderer.save);

		$('body').on('click', '.delete-img', function() {
			$("[name='img2del-img']").remove();
			$("[name='removeOldimg']").val('1');
		});

		$('body').on('click', '.delete', function() {
			var url = '/admin/' + $("[name='page']").val() + '/delete/' + $(this).attr('data-id');
			$('.modalDelete').modal('show');
			$('.doDelete').attr('href', url);
			$('.doDelete').attr('data-id', $(this).attr('data-id'));
		});

		$('body').on('click', '.validate', function() {
			var url = '/admin/' + $("[name='page']").val() + '/validate/' + $(this).attr('data-id');
			$('.modalValidate').modal('show');
			$('.doValidate').attr('href', url);
			$('.doValidate').attr('data-id', $(this).attr('data-id'));
		});

		$('body').on('click', '.doDelete', function() {
			var ref = $(this).attr('href');
			var id = $(this).attr('data-id');

			callData = {
				'_token' : $('meta[name="csrf-token"]').attr('content')
			};

			$.post(ref, callData, function(data) {
				if (data.status == "ok") {
					if(!($('.delete[data-id='+id+']').hasClass('tb'))){
						var row = $('.delete[data-id='+id+']').parents('tr:first');
					}else{
						var row = $('.delete[data-id='+id+']').parents('tbody:first');
					}
					row.remove();
				}
				if (data.status == "photo_ok") {
					var row = $('.col-md-2[data-id='+id+']');
					row.remove();
				}
				if (data.status == "photo_ko") {
					alert("No s'ha pogut eliminar la foto perque està en ús.");
				}
				$('.modalDelete').modal('hide');
			});

			return false;
		});

		$('body').on('click', '.doValidate', function() {
			var ref = $(this).attr('href');
			var id = $(this).attr('data-id');

			callData = {
				'_token' : $('meta[name="csrf-token"]').attr('content')
			};

			$.post(ref, callData, function(data) {
				if (data.status == "ok") {
					window.location.assign(window.location.pathname+'#form');
				}else{
					alert("No s'ha pogut aprobar la opinió.");
				}
				$('.modalValidate').modal('hide');
			});

			return false;
		});

	}

}

var orderer = {
	setUpDown: function() {
		var row = $(this).parents("tr:first");
		if ($(this).is(".up")) {
			row.insertBefore(row.prev());
		} else {
			row.insertAfter(row.next());
		}
			
		$('.btn-order').removeClass('hide');
	},
	save: function() {
		$('.order-feedback').html('<img src="/assets/img/preload.gif" />').show();

		var items = new Array();
		$('.orderRows').each(function(k,v) {
			var order = $(v).attr('data-id');
			items.push(order);
		});

		callData = {
			'items': items,
			'_token' : $('meta[name="csrf-token"]').attr('content')
		};

		var page = $('[name="page"]').val();

		$.post('/admin/' + page + '/order', callData, function(data) {
			if (data.status == "ok") {
				$('.order-feedback').html('<i class="fa fa-thumbs-up"></i> Guardado').fadeOut(500);
				$('.btn-order').addClass('hide');
			}
		});
	}

}


main.initialize();